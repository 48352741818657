.test-circle-container{
    width: 100%;
    aspect-ratio: 1/1;
}

.test-circle{
    border-radius: 50%;
    cursor: pointer;
    height: 95%;
    width: 95%;
    transition: height 0.2s, width 0.2s;
}

.test-circle:hover{
    height: 100%;
    width: 100%;
}