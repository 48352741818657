.combine{
    margin-top: 100px;
}

.combine-item{
    text-align: center;
    text-transform: uppercase;
    color: var(--dark-accent);
    font-size: calc(1vw + 15px);
    font-weight: 800;
    cursor: pointer;
    transition: color 0.2s;
    box-sizing: border-box;
    padding: 0vw 2vw;

    width: calc(33.33% - 20px);
}

.combine-item:hover{
    color: var(--light-accent);
}

.combine-item.active{
    color: var(--primary-brand);
}

.combine-text{
    margin-top: 50px;
    transition: height 0.2s;
}

.combine-text-item{
    display: none;
    text-align: center;
    color: var(--light-accent);
    font-size: 18px;
    line-height: 1.5;
}

.combine-text-item.active{
    display: block;
}
p{
 color: var(--dark-accent);
}

.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 400px;

}

.swiper-slide img {
    display: block;
    width: 100%;
}

.menu-container{
    position: relative;
    width: calc(100% - 4vw);
    height: auto;
    padding: 2vw;
    background-color: var(--light-shade);
}

.menu-title{
    position: relative;
    width: 100%;
    border-bottom: 1px solid var(--dark-accent);
    font-weight: bold;
    margin-bottom: min( 28px, max( 18px ,2vw));
    font-size: min(25px, max(20px, 2.5vw));
    color: var(--dark-accent);

}

.clicker{
    cursor: pointer;
}
.description{
    font-size: min( 16px, max( 14px,2.5vw));
    max-font-size: 30px;
    margin: 4px;
    margin-bottom: 20px;

}
.order-now{
    width: calc(100% - 16px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: min( 16px, max( 14px,2.5vw));
    max-font-size: 30px;
    margin: 8px;
    margin-bottom: min( 16px, max( 14px,2.5vw));
}

@media screen and (max-width: 650px) {
    .swiper{
        width: 160%!important;
        margin-left: -30%;
    }
    .description{
        font-size: min( 16px, max( 13px,2.5vw));
        max-font-size: 30px;
        margin: 4px;
        margin-bottom: 20px;

    }
}

@media screen and (max-width: 400px) {
    .swiper{
        width: 180%!important;
        margin-left: -40%;
    }
    .description{
        font-size: min( 16px, max( 13px,2.5vw));
        max-font-size: 30px;
        margin: 8px;
        margin-bottom: 20px;

    }
}


