body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark-shade);
  background-color: var(--light-shade);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* colour */
/* ls = light shade */
/* ds = dark shade */
/* pb = primary brand */
/* la = light accent */
/* da = dark accent */
.text-ls{
  color: var(--light-shade);
}

.text-ds{
  color: var(--dark-shade);
}

.text-pb{
  color: var(--primary-brand);
}

.text-la{
  color: var(--light-accent);
}

.text-da{
  color: var(--dark-accent);
}

.bg-ls{
  background-color: var(--light-shade);
}

.bg-ds{
  background-color: var(--dark-shade);
}

.bg-pb{
  background-color: var(--primary-brand);
}

.bg-la{
  background-color: var(--light-accent);
}

.bg-da{
  background-color: var(--dark-accent);
}
/* structure */
body{
  margin: 0px;
}

.container{
  margin: 0 auto;
  width: 1000px;
}

.narrow{
  max-width: 800px;
}

.margin-auto{
  margin: auto;
}

.center{
  margin: 0 auto;
}

.padding-vertical-40{
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
}

.padding-vertical-20{
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.margin-top-20{
  margin-top: 20px;
}

.margin-bottom-20{
  margin-bottom: 20px;
}

.float-right{
  float: right;
}

.float-left{
  float: left;
}

.relative{
  position: relative;
}

.absolute{
  position: absolute;
}

.height-100{
  height: 100%;
}

.width-100{
  width: 100%;
}

.flex{
  display: flex;
}

.flex-horizontal-center{
  justify-content: center;
}

.flex-vertical-center{
  align-items: center;
}

.flex-grow-1{
  flex-grow: 1;
}

.flex-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
}

.flex-row > * {
  padding: 0 10px;
}

.flex-row .col-100 {
  width: calc(100% - 20px);
}

.flex-row .col-80 {
  width: calc(80% - 20px);
}

.flex-row .col-70 {
  width: calc(70% - 20px);
}

.flex-row .col-50 {
  width: calc(50% - 20px);
}

.flex-row .col-33 {
  width: calc(33.33% - 20px);
}

.flex-row .col-30 {
  width: calc(30% - 20px);
}

.flex-row .col-25 {
  width: calc(25% - 20px);
}

.flex-row .col-20 {
  width: calc(20% - 20px);
}

/* ---------- MEDIA ---------- */
@media only screen and (max-width: 1050px) {
  .container{
      width: 96%;
  }
}

@media only screen and (max-width: 940px) {
  .flex-row .col-80,
  .flex-row .col-70,
  .flex-row .col-33,
  .flex-row .col-30,
  .flex-row .col-20 {
      width: calc(100% - 20px);
  }
}

@media only screen and (max-width: 625px) {
  .flex-row .col-50 {
      width: calc(100% - 20px);
  }
}
