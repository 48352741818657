.home-banner{
    height: calc(100VH - 61px);
    overflow: hidden;
}

.banner-image{
    background-size: cover;
    background-position: center;
    position: absolute;
    min-width: 100%;
    min-height: 100vh;
    z-index: -1;

}



h1{
    text-align: center;
    font-size: calc(3vw + 15px);
    color: var(--light-shade);
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.32);
    font-weight: 800;

    -webkit-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation-delay: 1s;
    -o-animation-delay:1s;
}

h2{
    text-align: center;
    font-size: calc(2vw + 15px);
    font-weight: 800;
}

.about-section{
    padding-top: 100px;
}

.about-text{
    color: var(--light-accent);
    font-size: 18px;
    line-height: 1.5;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.about-text a{
    color: var(--light-accent);
    transition: color 0.2s;
    text-decoration: underline;
}

.about-text a:hover{
    color: var(--primary-brand);
}

/* ----------------------------------------------
 * Generated by Animista on 2024-5-3 17:34:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-bottom {
    0% {
        -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}
@keyframes slide-in-blurred-bottom {

    0% {
        -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

.slide-in-blurred-bottom {

    -webkit-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-5-3 17:48:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-bottom
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-bottom {
    0% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        -webkit-transform-origin: 50% 84%;
        transform-origin: 50% 84%;
    }
    100% {
        -webkit-transform: scale(1.25) translateY(15px);
        transform: scale(1.25) translateY(15px);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
    }
}
@keyframes kenburns-bottom {
    0% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        -webkit-transform-origin: 50% 84%;
        transform-origin: 50% 84%;
    }
    100% {
        -webkit-transform: scale(1.25) translateY(15px);
        transform: scale(1.25) translateY(15px);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
    }
}


.kenburns-bottom {
    -webkit-animation: kenburns-bottom 1.5s cubic-bezier(0.445, 0.050, 0.550, 0.950) alternate-reverse both;
    animation: kenburns-bottom 1.5s cubic-bezier(0.445, 0.050, 0.550, 0.950) alternate-reverse both;
}

@media screen and (max-width: 867px) {
    .banner-image{
        background-size: cover;
        background-position: center;
        position: absolute;
        min-width: 100%;
        min-height: 100vh;
        z-index: -1;
        width: 400%;
    }
}
