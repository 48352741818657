.text-image-container{
    margin-bottom: 50px;
}

.text-image-container p{
    margin-top: 20px;
    color: var(--light-accent);
    padding: 0vw 1vw;
    font-size: 18px;
    line-height: 1.5;
}

.text-image-container p a{
    color: var(--light-accent);
    transition: color 0.2s;
    text-decoration: underline;
}

.text-image-container p a:hover{
    color: var(--primary-brand);
}

h3{
    color: var(--light-accent);
    transition: color 0.6s;
    font-size: 24px;
    text-transform: uppercase;
}

.text-image-container:hover h3{
    color: var(--primary-brand);
}

.floating-image{
    width: 100%;
    max-width: 200px;
    padding-top: 10px;

    animation: float 6s;
    animation-iteration-count: infinite;
}

.floating-image-1{
    animation-delay: 0s;
}

.floating-image-2{
    animation-delay: 2s;
}

.floating-image-3{
    animation-delay: 4s;
}

@keyframes float {
    0%   {padding-top: 10px; padding-bottom: 0px; }
    50%  {padding-top: 0px;  padding-bottom: 10px; }
    100% {padding-top: 10px; padding-bottom: 0px;}
}