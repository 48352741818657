footer{
    color: var(--light-shade);
}

footer h3{
    font-size: 3.5vw;
}

.footer-bridge{
    width: 100%;
    margin-bottom: -4px;
}

.footer-logo{
    width: 50%;
    max-width: 150px;
}

.footer-links .footer-link{
    margin-bottom: 10px;
    width: fit-content;
    color: var(--light-shade);
    transition: color .2s;
}

.footer-links .footer-link:hover{
    color: var(--primary-brand);
}

.footer-links, .social-icons{
    box-sizing: border-box;
    padding-left: 10vw;
}

.copyright-text, .social-icons{
    margin-top: 50px;
}

.copyright-links{
    margin-top: 20px;
}

.copyright-links a{
    color: var(--light-shade);
    transition: color 0.2s;
    border-bottom: 1px dotted var(--light-shade);
}

.copyright-links a:hover{
    color: var(--primary-brand);
}

.social-icons .fa{
    font-size: 20px;
    padding: 0px 20px;
    color: var(--light-shade);
    transition: color 0.2s;
}

.social-icons .fa:hover{
    color: var(--primary-brand);
}

