.contact-banner{
    margin-top: 100px;
    cursor: pointer;
    transition: background-color 0.5s;
}

.contact-banner h2{
    transition: color 0.5s;
    margin: 0px;
    padding: 40px 0px;
}

.contact-banner:hover{
    background-color: var(--primary-brand);
}

.contact-banner:hover h2{
    color: var(--dark-shade);
}

.bridge{
    width: 100%;
    vertical-align: top;
}
#contact-section{
    scroll-behavior: smooth;
}