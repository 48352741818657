#content{
    margin-top: 100px;
}

p{
    font-size: 18px;
    line-height: 1.5;
    margin: 18px 0px;
}

ul{
    padding-left: 50px;
}

.privacy-nav .bg-ds{
    height: 50px;
}

.privacy-nav .nav-item {
    color: var(--light-shade);
}

.privacy-nav .nav-item:hover {
    color: var(--primary-brand);
}