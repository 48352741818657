.contact-form h2{
    margin-bottom: 40px;
    margin-top: 50px;
}

#contact_form{
    margin-bottom: 50px;
}

#message{
    height: 44px;
    overflow-y: hidden;
}

#confirm{
    width: fit-content;
    height: auto;
    margin-left: 20px;
}

input, textarea {
    width: 100%;
    height: 40px;
    resize: none;
    font-size: 18px;
    border-style: none;
    margin-bottom: 20px;
    font-family: "Open Sans", sans-serif;
    color: var(--dark-shade);
    background-color: transparent;
    transition: color 0.2s, border-color 0.2s;
    border-bottom: 1px solid var(--dark-shade);
}

::placeholder {
    color: var(--dark-accent);
}
  
::-ms-input-placeholder { /* Edge 12 -18 */
    color: var(--dark-accent);
}

input:focus, textarea:focus{
    outline: none;
    /* color: var(--light-shade);
    border-color: var(--light-shade); */
}

button{
    border: none;
    padding: 15px 40px;
    font-size: calc(1vw + 5px);
    font-weight: 700;
    border-radius: 5px;
    cursor: pointer;
    color: var(--light-shade);
    background-color: var(--dark-accent);
    transition: color 0.2s, background-color 0.2s;
}

button:hover{
    background-color: var(--primary-brand);
}
