nav{
    background-color: var(--dark-shade);
    position: relative;
    z-index: 999;
}

.nav-item{
    padding-top: 20px;
    padding-bottom: 20px;
    
}

a{
    color: var(--light-accent);
    text-decoration: none;
    transition: color 0.2s;
}

a:hover{
    color: var(--primary-brand);
    /* color: var(--dark-accent); */
}
